/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      $('#collapsePlaylist').on('shown.bs.collapse', function () {
        $('a.playlistOpenerText').html('Playlist verbergen<br /><i class="fa fa-chevron-down"></i>');
      })

      $('#collapsePlaylist').on('hidden.bs.collapse', function () {
        $('a.playlistOpenerText').html('<i class="fa fa-chevron-up"></i><br />Playlist anzeigen');
      })
         
        var volume = 0.5;
        $('.jp-play').on('click', function() {
           $("#jquery_jplayer_1").jPlayer({
    	      ready: function () {
    	        $(this).jPlayer("setMedia", {
    	          title: "Bubble",
    	          mp3: "https://stream.ffn.de/radioroland/mp3-192/stream.mp3",
    	          m4a: "https://stream.ffn.de/radioroland/aac-48/"
              });
              $(this).jPlayer("play");
    	      },
    	      cssSelectorAncestor: "#jp_container_1",
            swfPath: "./jquery.jplayer.swf",
            solution: "html,flash",
            supplied: "mp3, acc",
            errorAlerts: false,
            warningAlerts: false, 
    	      useStateClassSkin: true,
    	      autoBlur: false,
    	      smoothPlayBar: true,
    	      keyEnabled: true,
    	      remainingDuration: true,
    	      toggleDuration: true,
    	      volume: volume
    	    });  
        });

        $('.jp-stop').on('click', function() {
          $("#jquery_jplayer_1").jPlayer("stop");
          $("#jquery_jplayer_1").jPlayer("destroy");
        });

    	  $('.jp-decrease-vol').on('click', function() {
    		 if(volume > 0.1) { volume = volume - 0.1; } 
    		 $("#jquery_jplayer_1").jPlayer("volume", volume);
    	  });
    	  
    	  $('.jp-increase-vol').on('click', function() {
     		 if(volume < 0.9) { volume = volume + 0.1; } 
     		 $("#jquery_jplayer_1").jPlayer("volume", volume);
     	  });
    	  
        var protocol = location.protocol;
        var slashes = protocol.concat("//");
        var host = slashes.concat(window.location.hostname);

    	  var playlistXML = 'https://www.ffn.de/fileadmin/content/playlist-xml/radioroland.xml';
        var proxy = host+"/proxy.php?dst="+playlistXML;

    	  function loadArtistInformation() {

    		  $.ajax({
    			    type: 'GET',
    			    dataType: "json",
    			    url: proxy,
    			    //converters: {"xml": jQuery.parseXML},
    			    success: function (responseData, textStatus, jqXHR) {
                
                /*
                var timeNow = Date.now();
                var ts = Date.parse(responseData.onair.start);
                var durTotal = responseData.onair.duration.substring(0, responseData.onair.duration.length - 3);
                var durMin = (durTotal.substring(0, durTotal.length - 2)/1);
                var durSec = (durTotal.substring(durTotal.length - 2, durTotal.length)/1);
                var durSafe = (6*1000);
                var compareTs = (ts + (((durMin*60) + durSec)*1000) + durSafe );

                if(timeNow > compareTs) {

                  $('.footer_artist_title > .footer_artist_title--artist').text(responseData.next.artist);
                  $('.footer_artist_title > .footer_artist_title--title').text(responseData.next.title);
                  $('.footer_artist_title > .footer_artist_title--artist-title').text( responseData.next.artist + ' - ' + responseData.next.title);

                } else {

                  $('.footer_artist_title > .footer_artist_title--artist').text(responseData.onair.artist);
                  $('.footer_artist_title > .footer_artist_title--title').text(responseData.onair.title);
                  $('.footer_artist_title > .footer_artist_title--artist-title').text( responseData.onair.artist + ' - ' + responseData.onair.title);

                }

                */
               $('.footer_artist_title > .footer_artist_title--artist').text(responseData.onair.artist);
               $('.footer_artist_title > .footer_artist_title--title').text(responseData.onair.title);
               $('.footer_artist_title > .footer_artist_title--artist-title').text( responseData.onair.artist + ' - ' + responseData.onair.title);

    			    },
    			    error: function (responseData, textStatus, errorThrown) {
    			    	 console.log(responseData);
    			    }
    			 });
    	  }
    	  
    	  
    	  loadArtistInformation();
    	  
    	  setInterval(function(){ 
    		  loadArtistInformation();
    	  }, 10000);
    	  
        
        

      
    	
     	  
    	  
    	  
    	  
    	  
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
